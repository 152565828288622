@keyframes slideInUp {
  0% {
      transform: translate3d(0,100%,0);
      visibility: visible
  }

  to {
      transform: translateZ(0)
  }
}

.slideInUp {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: slideInUp
}

@keyframes slideInDown {
  0% {
      transform: translate3d(0,-100%,0);
      visibility: visible
  }

  to {
      transform: translateZ(0)
  }
}

.slideInDown {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: slideInDown
}
