* {
  box-sizing: border-box;
  font-family: OpenSans-Light;
}

body {
  margin: 0;
  padding: 0;
  -webkit-print-color-adjust: exact;
}

p {
  margin: 0;
}
