@font-face {
  font-family: 'OpenSans-Light';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/opensans-light-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/opensans-light-webfont.woff2') format('woff2'),
    url('../assets/fonts/opensans-light-webfont.woff') format('woff'),
    url('../assets/fonts/opensans-light-webfont.ttf') format('truetype'),
    url('../assets/fonts/opensans-light-webfont.svg#open_sansregular') format('svg');
}
