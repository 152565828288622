main {
  padding: 35px 20px;
  max-width: 750px;
  margin: 0 auto;
}

header,
footer {
  background: $brand-primary;
  color: $white;
  position: fixed;
  width: 100%;
}

@media (min-width: 600px) {
  footer {
    position: fixed;
    top: 0;
    height: 65px;
  }
  header.phase-2 {
    top: 65px;
  }
  main {
    padding: 100px 20px !important;  
  }
}

header {
  background: $white;
}

footer {
  bottom: 0;
}
