.btn-submit {
  border-radius: 4px;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: $white;
  background: #007bff;
  border: 1px solid #007bff;
  -webkit-appearance: button;
  &:disabled {
    background: #aba9a9;
    border: 1px solid #aba9a9
  }
}
