.label {
  border: 1px solid $brand-primary-light;
  color: $brand-primary-light;
  border-radius: 20px;
  width: 100%;
  display: block;
  margin-bottom: 7px;
  padding: 7px;
  text-align: center;
}

input[type="checkbox"],
input[type="radio"],
.radio {
  opacity: 0;
  position: absolute;
  &:checked {
    + label {
      background: $brand-success;
      color: #2d3e4f;
      border-color: $brand-success;
    }
  }
}

.radio-classic{
  opacity: 1 !important;
  position: relative !important;
  margin: 10px 0 !important;
}

.label-classic {
  border: 0;
  display: inline;
}

.matrix-disp {
  text-align: left;
}

.flex-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.w-16 {
  width: 16.5%;
  height: 50px;
}

textarea {
  width: 100%;
}

.form-control {
  padding: .375rem .75rem;
  border: 1px solid $brand-primary-light;
  border-radius: .25rem;
  font-size: 1rem;
  line-height: 1.5;
  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  }
}

.selected-radio{
  width: 10px;
  height: 10px;
  border: 1px solid blue;
  border-radius: 50%;
  padding: 5px;
}

.first-radio_label {
  margin-left: -25px !important;
}

.other-radio-label {
  margin-left: -7px;
}